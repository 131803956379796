define("discourse/plugins/swapd-pm-notes/discourse/templates/connectors/topic-above-suggested/pm-note", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.pm_notes_enabled}}
    {{#if this.currentUser.staff}}
      {{#if this.args.model.isPrivateMessage}}
        {{edit-pm-note topic=this.args.model notes=this.args.model.pm_notes}}
      {{/if}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "qT/4mZgS",
    "block": "[[[41,[30,0,[\"siteSettings\",\"pm_notes_enabled\"]],[[[41,[30,0,[\"currentUser\",\"staff\"]],[[[41,[30,0,[\"args\",\"model\",\"isPrivateMessage\"]],[[[1,\"      \"],[1,[28,[35,1],null,[[\"topic\",\"notes\"],[[30,0,[\"args\",\"model\"]],[30,0,[\"args\",\"model\",\"pm_notes\"]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"edit-pm-note\"]]",
    "moduleName": "discourse/plugins/swapd-pm-notes/discourse/templates/connectors/topic-above-suggested/pm-note.hbs",
    "isStrictMode": false
  });
});