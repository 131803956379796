define("discourse/plugins/swapd-pm-notes/discourse/templates/components/edit-pm-note", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>
    {{d-icon "edit" class="pm-notes-edit"}}
  
    {{#if this.notes}}
      {{bound-avatar-template this.notes.avatar "small"}}
    {{/if}}
  
    {{i18n "pm_notes.notes"}}
  
    {{#if this.notes}}
      -
      {{i18n "pm_notes.last_edited_by"}}
      {{this.notes.username}}
      -
      {{{this.date}}}
    {{/if}}
  
    {{#unless this.editing}}
      {{d-button
        action=(action "edit")
        icon="pencil"
        class="btn-flat fright"}}
    {{/unless}}
  </h3>
  
  {{#if this.editing}}
    <Textarea @value={{this.note_copy}} rows="5" />
  
    <div>
      {{d-button
        action=(action "cancel")
        label="cancel"
        class="btn-danger"
        disabled=this.saving}}
  
        {{d-button
          action=(action "save")
          label="save"
          class="btn-primary"
          disabled=this.saving}}
    </div>
  {{else}}
    {{#if this.notes}}
      <div class="pm-notes">{{this.notes.note}}</div>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "yg81lk07",
    "block": "[[[10,\"h3\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"edit\"],[[\"class\"],[\"pm-notes-edit\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"notes\"]],[[[1,\"    \"],[1,[28,[35,3],[[30,0,[\"notes\",\"avatar\"]],\"small\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,4],[\"pm_notes.notes\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"notes\"]],[[[1,\"    -\\n    \"],[1,[28,[35,4],[\"pm_notes.last_edited_by\"],null]],[1,\"\\n    \"],[1,[30,0,[\"notes\",\"username\"]]],[1,\"\\n    -\\n    \"],[2,[30,0,[\"date\"]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,0,[\"editing\"]]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"action\",\"icon\",\"class\"],[[28,[37,7],[[30,0],\"edit\"],null],\"pencil\",\"btn-flat fright\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"  \"],[8,[39,8],[[24,\"rows\",\"5\"]],[[\"@value\"],[[30,0,[\"note_copy\"]]]],null],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,6],null,[[\"action\",\"label\",\"class\",\"disabled\"],[[28,[37,7],[[30,0],\"cancel\"],null],\"cancel\",\"btn-danger\",[30,0,[\"saving\"]]]]]],[1,\"\\n\\n      \"],[1,[28,[35,6],null,[[\"action\",\"label\",\"class\",\"disabled\"],[[28,[37,7],[[30,0],\"save\"],null],\"save\",\"btn-primary\",[30,0,[\"saving\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"notes\"]],[[[1,\"    \"],[10,0],[14,0,\"pm-notes\"],[12],[1,[30,0,[\"notes\",\"note\"]]],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"h3\",\"d-icon\",\"if\",\"bound-avatar-template\",\"i18n\",\"unless\",\"d-button\",\"action\",\"textarea\",\"div\"]]",
    "moduleName": "discourse/plugins/swapd-pm-notes/discourse/templates/components/edit-pm-note.hbs",
    "isStrictMode": false
  });
});